// Useful: https://phrase.com/blog/posts/nuxt-js-tutorial-i18n/#using-custom-date-formats
import datetimeFormats from '../.app/locales/datetimeFormats'
import en from '../.app/locales/en.json'
import nl from '../.app/locales/nl.json'

// import nl and nl locales from a file that overwrites any localizations in the nl and en files from the .app layer
import _ from 'lodash'
import overrideEn from './locales/overrides.en.json' // Import custom English localizations
import overrideNl from './locales/overrides.nl.json' // Import custom Dutch localizations

// Localizations that aren't overrides, but meedoen specific
import meedoenEn from './locales/meedoen.en.json'
import meedoenNL from './locales/meedoen.nl.json'

// Utility function to merge localizations
function mergeLocalizations(defaultLocales, customLocales) {
  return _.merge({}, defaultLocales, customLocales)
}

// console.log(`----overrideNl:----`)
// console.log(overrideNl)
// console.log(`----merged:-----`)
// console.log(mergeLocalizations(nl, overrideNl)[`vacancy`])

export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'nl',
  availableLocales: ['en', 'nl'],
  fallbackLocale: 'nl',
  strategy: 'no_prefix',
  detectBrowserLanguage: {
    useCookie: true,
    cookieKey: 'i18n_redirected',
    redirectOn: 'root', // recommended
  },
  messages: {
    en: mergeLocalizations(mergeLocalizations(en, overrideEn), meedoenEn),
    nl: mergeLocalizations(mergeLocalizations(nl, overrideNl), meedoenNL),
  },
  datetimeFormats,
}))
